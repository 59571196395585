import {Component} from '@angular/core';

@Component({
    selector: 'eaglei-nom-attribution',
    templateUrl: './nom-attribution.component.html',
    styleUrls: ['./nom-attribution.component.scss'],
    standalone: false,
})
export class NomAttributionComponent {
    constructor() {}
}
